import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import {
  FaBuilding,
  FaMobileAlt,
  FaDesktop,
  FaShoppingCart,
} from 'react-icons/fa'
import Lottie from 'react-lottie'
import Img from 'gatsby-image'

import AppAnimationData from '../lottie/iso1.json'
import WebAppAnimationData from '../lottie/iso3.json'
import WebsiteAnimationData from '../lottie/iso6.json'
import SEOAnimationData from '../lottie/iso7.json'
import Responsive from 'react-responsive'

const Mobile = props => <Responsive {...props} maxWidth={991} />
const Desktop = props => <Responsive {...props} minWidth={992} />

const Separator = styled.div`
  border-color: #fe1d03;
  border-top-width: 4px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100px;

  border-bottom-style: solid;
  border-top-style: solid;

  height: auto;
  border-bottom-width: 0;
`

const SolucionesPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Soluciones"
        keywords={[
          `ember`,
          `apps`,
          `páginas web`,
          `aplicaciones`,
          `consultoria`,
          `soluciones`,
        ]}
      />
      <section className="section">
        <div className="container" style={{ marginTop: '80px' }}>
          <div className="content">

          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <div className="has-text-centered">
                <span className="is-size-1 has-text-weight-bold">
                  Flujo de desarrollo seguro
                </span>
                <Separator />
                <p className="has-text-centered" style={{ fontSize: '23px', marginTop: '-35px'}}>
                  Todos nuestros proyectos cuentan con un flujo de ciclo de vida de desarrollo, esto para que las aplicaciones sean seguras desde su diseño hasta su mantenimiento.
                </p>
              </div>
              
            </div>
          </div>

          <div className="columns">
            <div className="column is-three-fifths is-offset-one-fifth">
            <Img
              style={{ width: '100%', alignItems: 'center' }}
              fluid={data.flujo.childImageSharp.fluid}
              alt="flujo desarrollo"
            />
            </div>
          </div>
          

            <div className="columns" style={{ marginTop: '50px' }}>
              <div className="column">
                <h1>Apps móviles</h1>
                <Separator />
                <p className="has-text-justified">
                  Llega a los celulares desde una aplicación móvil, descargada
                  desde las tiendas de aplicaciones o de manera interna para tu
                  organización, totalmente personalizada a tus necesidades tanto
                  en iPhone como en Android.
                </p>
                <div className="columns">
                  <div
                    className="column"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FaBuilding
                        style={{
                          fontSize: '36px',
                          marginRight: '10px',
                          color: '#fe1d03',
                        }}
                      />
                      <h2>Internas</h2>
                    </div>
                    <p className="has-text-justified">
                      Aplicaciones para gestionar, compartir información,
                      capacitar, altas de clientes, cualquier acción que pueda
                      imaginar desde los dispositivos móviles de sus
                      colaboradores
                    </p>
                  </div>
                  <div className="column">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FaMobileAlt
                          style={{
                            fontSize: '36px',
                            marginRight: '10px',
                            color: '#fe1d03',
                          }}
                        />
                        <h2>Descargables</h2>
                      </div>
                      <p className="has-text-justified">
                        Llega a las tiendas de aplicaciones tanto de iPhone como
                        Android para que cualquiera pueda descargar tu app y haz
                        tu idea realidad con nosotros.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <Desktop>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: AppAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height="80%"
                    width="80%"
                  />
                </Desktop>
                <Mobile>
                  <Img
                    fluid={data.iso1.childImageSharp.fluid}
                    alt="app animation"
                  />
                </Mobile>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <h1>Aplicaciones Web</h1>
                <Separator />
                <p className="has-text-justified">
                  Plataformas web interactivas que se acceden desde un navegador
                  web a través de una URL, lo único que se necesita es una
                  pantalla y conexión a internet para que tus clientes o
                  colaboradores puedan ver e interactuar con tu aplicación desde
                  cualquier dispositivo. Solamente tienes que imaginarlo y
                  nosotros lo hacemos realidad usando las últimas y mejores
                  tecnologías
                </p>
              </div>
              <div className="column">
                <Desktop>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: WebAppAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height="80%"
                    width="80%"
                  />
                </Desktop>
                <Mobile>
                  <Img
                    fluid={data.iso3.childImageSharp.fluid}
                    alt="web app animation"
                  />
                </Mobile>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <h1>Sitios web</h1>
                <Separator />
                <p className="has-text-justified">
                  Ten presencia en internet con una página que refleje tu
                  personalidad y que muestre lo mejor de tu empresa, llega a más
                  clientes haciendo uso de la tecnología ofreciendo e incluso
                  vendiendo desde tu propio sitio de e-commerce.
                </p>
                <div className="columns">
                  <div
                    className="column"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FaDesktop
                        style={{
                          fontSize: '36px',
                          marginRight: '10px',
                          color: '#fe1d03',
                        }}
                      />
                      <h2>Informativas</h2>
                    </div>
                    <p className="has-text-justified">
                      Página de internet que da información sobre quién eres,
                      que les ofreces y como contactarte de manera más sencilla.
                    </p>
                  </div>
                  <div className="column">
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FaShoppingCart
                          style={{
                            fontSize: '36px',
                            marginRight: '10px',
                            color: '#fe1d03',
                          }}
                        />
                        <h2>e-commerce</h2>
                      </div>
                      <p className="has-text-justified">
                        Ten tu propia tienda en línea y vende tus productos
                        desde la comodidad de tu casa a través de internet.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <Desktop>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: WebsiteAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height="80%"
                    width="80%"
                  />
                </Desktop>
                <Mobile>
                  <Img
                    fluid={data.iso6.childImageSharp.fluid}
                    alt="e-commerce animation"
                  />
                </Mobile>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <h1>Consultoría tecnológica</h1>
                <Separator />
                <p className="has-text-justified">
                  Te acompañamos en el proceso de transformación digital en tu
                  organización, brindándote las mejores recomendaciones para el
                  aprovechamiento máximo de la tecnología.
                </p>
                <ul>
                  <li>Mejora de procesos</li>
                  <li>Planificación de software</li>
                  <li>Tecnologías a utilizar</li>
                  <li>Digitalización</li>
                  <li>Optimización de sitios web</li>
                </ul>
              </div>
              <div className="column">
                <Desktop>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SEOAnimationData,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height="80%"
                    width="80%"
                  />
                </Desktop>
                <Mobile>
                  <Img
                    fluid={data.iso7.childImageSharp.fluid}
                    alt="seo animation"
                  />
                </Mobile>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    flujo: file(relativePath: { eq: "flujo.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iso1: file(relativePath: { eq: "iso1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iso3: file(relativePath: { eq: "iso3.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iso6: file(relativePath: { eq: "iso6.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iso7: file(relativePath: { eq: "iso7.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default SolucionesPage
